import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Support() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h1>Support</h1>
    </DashboardLayout>
  );
}

export default Support;
